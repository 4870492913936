import React, { useContext } from 'react';
import { MFContext, getSharedComponent } from '@4r/mf-host';
import { withRouter } from 'react-router-dom';
import { useConfig } from '@4r/mf-app';
import { useFeatureFlag } from '@4r/module-common-feature-flags-mf-host';
import { AccessContext } from '@4r/module-common-authorization';

import { useGlobalState } from '../../state/Store';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';
import { PageStatus } from '../../state/Actions';
import { FeatureFlags } from '../../common/consts';


const PropertyLifecyclePage: React.FC = () => {
	const { FormsMicrofrontend } = React.useContext(MFContext);
	const { user } = useContext(AccessContext);
	const [onlineStatus] = useGlobalState('online');
	if (onlineStatus === PageStatus.offline) {
		return <OfflineMessage pageTitle="Property Lifecycles" />;
	}

	// new Lifecycle dashboard
	const lifecycleDashboardEnabled = useFeatureFlag(FeatureFlags.PropertyLifecycles);
	if (!lifecycleDashboardEnabled || !FormsMicrofrontend) {
		return null;
	}
	const StandaloneDashboardView = getSharedComponent('DashboardView');
	if (!StandaloneDashboardView) {
		return null;
	}
	const config = useConfig('FormsMicrofrontend');
	return <StandaloneDashboardView config={config[0]} dashboardName="LifecyclesMaintenanceDashboard" currentUser={user} />;
};

export default withRouter(PropertyLifecyclePage);
